import { Injectable, EventEmitter } from '@angular/core';
import { QueueService, StringUtils } from '@pwc-ecobonus/common';
import { DownloadedItem, DownloadItemType } from '../models/downloaded-item';
import { switchMap, flatMap, takeWhile, tap, first, map } from 'rxjs/operators';
import { Observable, timer } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Logger } from '@nsalaun/ng-logger';

@Injectable({
  providedIn: 'root'
})
export class DownloaderService extends QueueService<DownloadedItem> {
  protected baseUrl: string = `${environment.apiUrl}`;
  protected endpointDownload: string = 'task/download';
  protected endpointDownloadStatus: string = 'folder/download';

  queue: DownloadedItem[] = [];

  onDownloadCompleted: EventEmitter<DownloadedItem> = new EventEmitter<DownloadedItem>();

  constructor(private http: HttpClient,
    private logger: Logger) {
    super();
  }

  removeById(id: number): void {
    this.queue = this.queue.filter(item => item.id !== id);
  }

  getQueue() {
    return this.queue;
  }

  addToQueue(item: DownloadedItem) {
    this.queue.push(item);
  }

  public downloadElements(items, endpoint?: string): Observable<any> {
    let ids = [];
    if (items.id.length) {
      ids = items.id;
    } else {
      ids.push(items.id);
    }

    if (endpoint == null) {
      endpoint = this.endpointDownload;
    }

    const url = `${this.baseUrl}/${endpoint}`;

    return this.http.post<any>(url, { ids }).pipe();
  }

  public getDownloadStatus(item: DownloadedItem): Observable<any> {
    const request = {
      id: item.id,
      type: 'TASK'
    };

    // if (item.itemType==DownloadItemType.CSV)
    return this.http.post<any>(`${environment.apiUrl}/${this.endpointDownload}/status`, request)
      .pipe();
  }

  public getMultipleDownloadStatus(item: DownloadedItem): Observable<any> {
    if (item.itemType === DownloadItemType.CSV) {
      return this.http.post<any>(`${environment.apiUrl}/${this.endpointDownloadStatus}/${item.id}/status`, null)
        .pipe();
    }
  }

  public getDownloadById(id: number): Observable<{ rawData: any, blob: Blob, url: string, fileName: string }> {
    let params = new HttpParams();
    params = params.append('id', `${id}`);
    params = params.append('type', 'TASK');

    return this.getBlob<Blob>(`byId`, params).pipe(
      map((response: HttpResponse<Blob>) => {
        this.logger.debug('response ->', response);

        let fileName = '';
        const contentDisposition = response.headers.get('Content-Disposition');
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, '');
        }

        let blob;
        blob = new Blob(['\ufeff', response.body]);
        const rawData = response.body;

        return {
          rawData,
          blob,
          url: URL.createObjectURL(blob),
          fileName
        };
      }));

  }

  public getDownloadByToken(token: string, extension?: string): Observable<{ blob: Blob, url: string, fileName: string }> {
    let params = new HttpParams();
    if (token != null) {
      params = params.set('token', `${token}`);
    }
    return this.getBlob<Blob>('download/byToken', params).pipe(
      map((response: HttpResponse<Blob>) => {
        this.logger.debug('response ->', response);

        let fileName = '';
        const contentDisposition = response.headers.get('Content-Disposition');
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, '');
        }

        this.logger.debug('extension', extension);

        let blob;
        if ((extension != null) && extension === '.csv') {
          blob = new Blob(['\ufeff', response.body]);
        } else {
          blob = new Blob([response.body]);
        }

        return {
          blob,
          url: URL.createObjectURL(blob),
          fileName
        };
      }));
  }

  protected getBlob<Blob>(url: string = '', param: HttpParams, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'arraybuffer';
    withCredentials?: boolean;
  }): Observable<HttpResponse<Blob>> {
    let params = '';
    if (param != null) {
      params = '?';
      param.keys().forEach(value => params += `${value}=${param.get(value)}&`);
    }
    return this.http.get<Blob>(`${environment.apiUrl}/${this.endpointDownload}/${url}${params}`, {
      responseType: 'blob' as 'json',
      observe: 'response'
    });
  }

  public downloadMultiple(items: DownloadedItem[]) {
    items.forEach(item => this.addToQueue(item));
    this.startDownloading();
  }

  public download(item: DownloadedItem) {
    this.logger.debug('addToQueue', item);
    this.addToQueue(item);
    this.startDownloading();
  }

  startDownloading(extension?: string) {
    for (const item of this.queue.filter(val => !val.isDownloading)) {
      if (!item.isError && !item.isSuccess) {
        item.isDownloading = true;
        const call = timer(0, 2000).pipe(
          flatMap(value => this.getDownloadStatus(item).pipe(
            takeWhile(val => val.status === 'READY' || val.status === 'ERROR'),
            tap(val => {
              item.fileName = val.fileName;
              item.isError = val.status === 'ERROR';
              item.isSuccess = val.status === 'READY';
            })
          )),
          first(),
          switchMap(item => {
            return this.getDownloadById(item.id);
          }),
        ).subscribe((res: { rawData: any, blob: Blob, url: string, fileName: string }) => {
          this.logger.debug('download item', res);
          item.isDownloading = false;
          if (item.isSuccess) {
            item.url = res.url;
            //@ts-ignore
            item.blob = new Blob([res.rawData]);
            item.url = res.url;
            item.rawData = res.rawData;
            if (!StringUtils.isEmpty(res.fileName)) {
              item.fileName = res.fileName;
            }
            call.unsubscribe();
            this.onDownloadCompleted.emit(item);
          }
        }, error => {
          this.logger.log(error);
          item.isSuccess = false;
          item.isDownloading = false;
          item.isError = true;
        }
        );
      }
    }
  }

  get isDownloading(): boolean {
    return this.queue.filter(val => val.isDownloading).length > 0;
  }

  get isError(): boolean {
    return this.hasItems && !this.isDownloading && this.queue.filter((item: DownloadedItem) => item.isError).length > 0;
  }

  get isSuccess(): boolean {
    return this.hasItems && !this.isDownloading && !this.isError;
  }

  public removeAll(): void {
    this.queue = [];
  }

  get totalItemsCount(): number {
    return this.queue.length;
  }

  get hasItems(): boolean {
    return this.totalItemsCount > 0;
  }
}
